<template>
    <div>
        <el-card>
            <nav class="out">
                <nav class="input">
                    <el-input v-model="form_2.keyword" size="medium" placeholder="输入发布人姓名，标题，内容搜索" />
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select v-model="form_2.isHide" placeholder="状态" size="medium">
                        <el-option label="全部" value="-1" />
                        <el-option label="上架" :value="0" />
                        <el-option label="下架" :value="1" />
                    </el-select>
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select v-model="form_2.type" placeholder="类型" size="medium">
                        <el-option label="全部" :value="-1" />
                        <el-option
                            v-for="(item, index) in configDictionaries.cooperationType"
                            :key="index"
                            :label="item.value"
                            :value="item.key"
                        />
                    </el-select>
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select v-model="form_2.tag" placeholder="版块" size="medium">
                        <el-option label="全部" value=" " />
                        <el-option
                            v-for="(item, index) in enTag"
                            :key="index"
                            :label="item.name"
                            :value="item.engName"
                        />
                    </el-select>
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select v-model="form_2.business" placeholder="行业" size="medium">
                        <el-option label="全部" value="-1" />
                        <el-option
                            v-for="(item, index) in configDictionaries.cooperationBusiness"
                            :key="item.key"
                            :label="item.value"
                            :value="item.key"
                        />
                    </el-select>
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select v-model="form_2.field" placeholder="领域" size="medium">
                        <el-option label="全部" value="-1" />
                        <el-option
                            v-for="(item, index) in configDictionaries.cooperationField"
                            :key="item.key"
                            :label="item.value"
                            :value="item.key"
                        />
                    </el-select>
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select v-model="form_2.area" placeholder="服务区域" size="medium">
                        <el-option label="全部" value="-1" />
                        <el-option
                            v-for="(item, index) in configDictionaries.cooperationServerArea"
                            :key="item.key"
                            :label="item.value"
                            :value="item.key"
                        />
                    </el-select>
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select v-model="form_2.recommend" placeholder="是否推荐" size="medium">
                        <el-option label="全部" value="-1" />
                        <el-option
                            v-for="(item, index) in recommendList"
                            :key="item.key"
                            :label="item.value"
                            :value="item.key"
                        />
                    </el-select>
                </nav>
                <nav class="input" style="width: 250px;">
                    <el-date-picker
                        v-model="form_2.createDate"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        style="width: 100%"
                        size="medium"
                    />
                </nav>
                <nav class="input" style="width: 150px">
                    <el-button size="small" @click="reset">重置</el-button>
                    <el-button type="primary" size="small" @click="subimt">{{ "搜索" }}</el-button>
                </nav>
                <nav class="input" style="width: auto;">
                    <load-button :click="exportIn">导出数据</load-button>
                </nav>
              <nav class="input" style="width: auto;">
                <el-button type="primary" size="small" @click="handleClickView">本周查看次数前十</el-button>
              </nav>
            </nav>
        </el-card>
        <el-card style="margin-top: 10px">
            <el-table
                :data="list"
                style="width: 100%"
                border
                stripe
                :header-cell-style="rowClass"
                @sort-change="sortChange"
            >
                <el-table-column prop="id" label="ID"> </el-table-column>
                <el-table-column prop="realName" label="姓名">
                    <template slot-scope="{ row }">
                        <div @click="toUser(row)" style="cursor: pointer;">
                            {{ row.realName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="类型">
                    <template slot-scope="{ row }">
                        <el-tag :type="row.type == 1 ? '' : 'warning'" size="small" class="box">{{
                            show_type(row.type)
                        }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="合作标题" width="150" :show-overflow-tooltip="true">
                    <template slot-scope="{ row }">
                        <p style="overflow: hidden;white-space: nowrap;text-overflow:ellipsis;">
                            <span :style="{ color: row.color ? 'red' : '' }"> {{ row.title }}</span>
                        </p>
                    </template>
                </el-table-column>

                <el-table-column prop="content" label="合作内容" width="200" :show-overflow-tooltip="true">
                    <template slot-scope="{ row }">
                        <p style="width: 180px; overflow: hidden;white-space: nowrap;text-overflow:ellipsis;">
                            {{ row.content }}
                        </p>
                        <el-image
                            v-for="item in fProductPicture(row.productPicture)"
                            :key="item"
                            :src="item"
                            style="width: 35px;height:35px;margin:4px"
                            :preview-src-list="[item]"
                        />
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="行业" width="130">
                    <template slot-scope="{ row }">
                        <el-tag
                            v-for="(item, index) in row.businessName"
                            :key="index"
                            type="warning"
                            size="small"
                            class="box"
                            >{{ item }}</el-tag
                        >
                    </template>
                </el-table-column>
                <el-table-column prop="fieldName" label="领域">
                    <template slot-scope="{ row }">
                        <el-tag
                            v-for="(item, index) in row.fieldName"
                            :key="index"
                            type="warning"
                            size="small"
                            class="box"
                            >{{ item }}</el-tag
                        >
                    </template>
                </el-table-column>
                <el-table-column prop="areaName" label="区域">
                    <template slot-scope="{ row }">
                        <el-tag
                            v-for="(item, index) in row.areaName"
                            :key="index"
                            type="warning"
                            size="small"
                            class="box"
                            >{{ item }}</el-tag
                        >
                    </template>
                </el-table-column>
                <el-table-column prop="endTimeType" label="合作截至时间">
                    <template slot-scope="{ row }">
                        <p>{{ row.endTimeType == 0 ? "长期有效" : row.endTime }}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="clickViews" label="浏览量"> </el-table-column>
                <el-table-column prop="status" label="状态">
                    <template slot-scope="{ row }">
                        <img
                            style="height: 15px;"
                            v-if="topLabel(row)"
                            src="https://static-cdn.yfchuhai.com/www.yfchuhai.com/static/wechat/img/cooperation/detail/zhiding_icon.png"
                            alt=""
                        />
                        <el-tag
                            :type="show_status(row.isHide) == '下架' ? 'info' : 'warning'"
                            size="small"
                            class="box"
                            >{{ show_status(row.isHide) }}</el-tag
                        >
                      <el-tag type="warning" size="small" class="box" v-if="row.recommend == 1">{{ "推荐" }}</el-tag>
                      <el-tag type="warning" size="small" class="box" v-if="row.promotion">{{ row.promotion==1?'推广中':'推广等待中' }}</el-tag>

                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="添加时间" />

                <el-table-column label="操作" width="150">
                    <template slot-scope="ew">
                        <el-button
                            v-show="$store.state.btn_if.indexOf('cooperation_cancel_check') != -1"
                            type="text"
                            icon="el-icon-refresh-left"
                            size="mini"
                            @click="cancelCheck(ew.row.id)"
                            >撤销审核</el-button
                        >
                        <el-button
                            v-show="$store.state.btn_if.indexOf('cooperation_edit') != -1"
                            type="text"
                            icon="el-icon-edit"
                            size="mini"
                            class="skyblue"
                            @click="edit(ew.row.id)"
                            >编辑</el-button
                        >

                        <el-button
                            v-show="$store.state.btn_if.indexOf('cooperation_delete') != -1"
                            type="text"
                            icon="el-icon-delete"
                            size="mini"
                            class="red"
                            @click="open(ew.row.id)"
                            >删除</el-button
                        >
                        <el-button
                            v-show="$store.state.btn_if.indexOf('cooperation_hide') != -1"
                            type="text"
                            icon="el-icon-s-promotion"
                            size="mini"
                            class="yellow"
                            @click="show(ew.row.id, ew.row.isHide == 0 ? 1 : 0)"
                        >
                            {{ ew.row.isHide == 0 ? "下架" : "上架" }}
                        </el-button>
                        <el-button
                            v-show="$store.state.btn_if.indexOf('cooperation_top') != -1"
                            type="text"
                            icon="el-icon-top"
                            size="mini"
                            style="color: tan;"
                            @click="$refs.topPop.init(ew.row)"
                        >
                            {{ "置顶" }}
                        </el-button>
                        <el-button
                            v-show="$store.state.btn_if.indexOf('cooperation_color') != -1"
                            type="text"
                            icon="el-icon-coordinate"
                            size="mini"
                            style="color: brown;"
                            @click="$refs.colorPop.init(ew.row)"
                        >
                            {{ "变色" }}
                        </el-button>
                        <el-button
                            v-show="$store.state.btn_if.indexOf('cooperation_fresh') != -1"
                            type="text"
                            icon="el-icon-refresh"
                            size="mini"
                            style="color:GoldEnrod;"
                            @click="cooperationTo(ew.row, '/admin/Cooperation/fresh')"
                        >
                            {{ "刷新" }}
                        </el-button>
                        <el-button
                            v-show="$store.state.btn_if.indexOf('cooperation_recommend') != -1"
                            type="text"
                            icon="el-icon-share"
                            size="mini"
                            style="color: green;"
                            @click="recommended(ew.row, '/admin/Cooperation/fresh')"
                        >
                            {{ ew.row.recommend == 1 ? "不推荐" : "推荐" }}
                        </el-button>
                      <el-button
                          v-show="$store.state.btn_if.indexOf('cooperation_change_manager') != -1"
                          type="text"
                          icon="el-icon-coordinate"
                          size="mini"
                          style="color: green;"
                          @click="switchingAdministrator(ew.row)"
                      >切换管理员
                      </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :current-page="currentPage"
                background
                :page-sizes="[3, 5, 10, 15]"
                :page-size="pagesize"
                layout="total, prev, pager, next"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </el-card>
        <top-pop ref="topPop"></top-pop>
        <colorPop ref="colorPop"></colorPop>
      <el-dialog :visible.sync="viewTableVisible" title="本周查看次数前十" width="800">
        <el-table :data="viewTop10Data">
          <el-table-column property="id" label="id" width="150" />
          <el-table-column property="title" label="标题" width="200" />
          <el-table-column property="num" label="查看次数" />
          <el-table-column property="createTime" label="创建时间" />
        </el-table>
      </el-dialog>
      <el-dialog :visible.sync="switchingAdministratorVisible" title="切换管理员" width="30%">
        <el-input v-model="form_4.phone" size="medium" placeholder="请输入要切换的管理员的手机号码" />
        <span slot="footer" class="dialog-footer">
        <el-button @click="switchingAdministratorVisible=false">取 消</el-button>
        <el-button type="primary" @click="switching">{{
            btn ? '确 定' : 'loading..'
          }}</el-button>
      </span>
      </el-dialog>
    </div>
</template>

<script>
var that;
import rules from "../../utils/rules";
import show from "../../components/countDowns/show.vue";
// import mixins from '../../mixin/getdiction'
import remark from "../../components/remark/index";
import topPop from "./cooperation_list/top-pop.vue";
import colorPop from "./cooperation_list/color-pop.vue";
import { recommendList } from "./data.js";
export default {
    components: {
        Show: show,
        Remark: remark,
        topPop,
        colorPop,

    },
    data() {
        return {
          switchingAdministratorVisible:false,
          viewTableVisible:false,
          viewTop10Data:[],
            title: "",
            year: "",
            publishStatus: "",
            reportArea: "",
            reportBusiness: "",
            order: "",
            confing: "",
            btn: true,
            action: {
                action: "enterprise",
            },
            dioaddkuai: false,
            currentPage: 1,
            pagesize: 10,
            list: [],
            pai: [],
            paiform: {},
            value: "",
            dialogVisible: false,
            dialogVisiblexui: false,
            baseurl: this.$store.state.env.cos,
            yu: "/" + this.$store.state.env.domain,
            total: 0,
            proof: false,
            form: {
                title: "",
                source: "",
                picture: "",
                content: "",
                desc: "",
                areaCode: "",
                banner: "",
                viewType: "",
                businessId: "",
            },
            form_1: {
                id: "",
                title: "",
                source: "",
                picture: "",
                content: "",
                desc: "",
                areaCode: "",
                banner: "",
                viewType: "",
                businessId: "",
            },
            form_2: {
                page: 1,
                pageSize: 10,
                keyword: "",
                isHide: "",
                type: "",
                area: "",
                field: "",
                createDate: [],
                recommend: "",
                tag: "",
                // order: ''
            },
            form_3: {
                phone: this.$cookies.get("phone"),
            },
            s_form: {
                page: 1,
                pageSize: 10,
                keyword: "",
                isHide: "",
                type: "",
                area: "",
                field: "",
                createDate: [],
                recommend: "",
                tag: "",
                // order: ''
            },
            imageUrl: "",
            imageUrl_1: "",
            header: {
                Token: sessionStorage.getItem("token"),
            },
            form_show: {},
            area: [],
            configDictionaries: {},
            enTag: [],
            recommendList,
          // 管理员切换表单
          form_4:{
            phone:''

          }
        };
    },
    computed: {
        show_status() {
            return (val) => {
                var key;
                switch (val) {
                    case 0:
                        key = "上架";
                        break;
                    case 1:
                        key = "下架";
                        break;
                }
                return key;
            };
        },
    },
    provide() {
        return {
            getlist: this.getlist,
        };
    },
    // mixins: [mixins],
    beforeCreate() {
        that = this;
    },
    updated() {
        //   console.log(this.form);
    },

    created() {
        // this.getconfig()
        // this.getconfig('user')
        this.getlist();
        this.getTag();
        this.$http.get("/admin/Common/getConfigDictionaries?config=cooperation").then(({ data: res }) => {
            this.configDictionaries = res.data;
        });
        sessionStorage.setItem("action", "cooperation");
    },
    mounted() {
        console.log(rules.req("撒打算"));
        // console.log(this.confing_d)
    },
    methods: {
      async switching(){
        console.log()
        let { data } = await this.$http.post("/admin/Cooperation/changeManager",{...this.form_4});
        if(data.errorCode==200){
          this.switchingAdministratorVisible=false
          this.getlist()
        }

      },
      async switchingAdministrator(row){
        this.switchingAdministratorVisible=true
        this.form_4.id=row.id
      },
      async handleClickView(){
        let { data } = await this.$http.get("/admin/Cooperation/getCooperationViewNum");
        this.viewTop10Data = data.data;
this.viewTableVisible=true
      },
        async cooperationTo(row, url) {
            let { data: res } = await this.$http.post(url, {
                id: row.id,
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlist();
            }
        },
        async getTag() {
            let { data } = await this.$http.get("/api/Cooperation/getTag");
            this.enTag = data.data;
        },
        async exportIn(done) {
            let { data: res } = await this.$http.post("/admin/Cooperation/export", this.updata(this.form_2));
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                location.href = res.data.url;
            }
            done();
        },
        toUser(val) {
            // console.log(val);
            let { userId } = val;
            this.$router.push({
                path: "/user_list",
                query: {
                    userId,
                },
            });
        },
        async recommended(val) {
            const { data: res } = await this.$http.post("/admin/Cooperation/recommend", {
                ...val,
                recommend: val.recommend == 1 ? 0 : 1,
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlist();
            }
        },
        async cancelCheck(id) {
            const { data: res } = await this.$http.post("/admin/Cooperation/cancelCheck", { id });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                if (this.total % 10 == 1) {
                    this.currentPage--;
                }
                this.getlist();
            }
        },
        reset() {
            // Object.assign(this.$data, this.$options.data.call(this));
            this.form_2 = this.s_form = this.$options.data.call(this).form_2;
            this.getlist();
            this.$http.get("/admin/Common/getConfigDictionaries?config=cooperation").then(({ data: res }) => {
                this.configDictionaries = res.data;
            });
        },
        fProductPicture(val) {
            if (val.includes("http")) {
                return val.split(",");
            } else {
                return [];
            }
        },
        subimt() {
            this.currentPage = 1;
            this.form_2.page = 1;
            this.s_form = { ...this.form_2 };
            this.getlist();
        },
        show_type(val) {
            if (val == 1) {
                return "提供服务";
            } else {
                return "需要资源";
            }
        },
        check() {
            console.log(1);
        },
        information(row) {
            this.showinfo = true;
            // this.form_show = row
            this.$http.get("/admin/UserSpeaker/getById", { params: { id: row.id } }).then(({ data: res }) => {
                if (res.errorCode == 200) {
                    this.form_show = res.data;
                }
            });
        },
        datum(row) {
            location.href = this.baseurl + this.yu + row.document;
        },
        sortChange(c) {
            console.log(c);
            if (c.order) {
                var s = "activity_" + c.prop;
                if (c.prop == "orderNum") {
                    s = "report_order_num";
                } else if (c.prop == "views") {
                    s = "report_views";
                } else {
                    s = "report_" + c.prop;
                }
                var p = c.order.slice(0, c.order.length - 6);
                this.order = s + " " + p;
            } else {
                this.order = "";
            }

            this.getlist();
        },

        addkuai() {
            this.addkuai = false;
        },
        getea() {
            this.$http.get("/admin/ReportCategory/getList").then(({ data: res }) => {
                if (res.errorCode == 200) {
                    this.area = res.data;
                }
            });
        },
        rowClass() {
            return "background:#F5F7FA;";
        },
        handleSizeChange(e) {
            this.pagesize = e;
            console.log(e);
        },
        handleCurrentChange(e) {
            // this.getlist()
            this.form_2.page = e;
            this.s_form.page = e;

            this.getlist(this.s_form);
        },
        getlist(val) {
            var form = val || this.form_2;
            this.$http
                .get("/admin/Cooperation/getList", {
                    params: this.updata(form),
                })
                .then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        console.log(res);
                        this.list = res.data.list;
                        // console.log(this.list)
                        this.total = res.data.totalCount;
                    }
                });
        },
        updata(form) {
            return {
                ...form,
                createDate: form.createDate.join("~"),
                area: form.area == -1 ? "" : form.area,
                field: form.field == -1 ? "" : form.field,
                isHide: form.isHide == -1 ? "" : form.isHide,
                tag: form.tag.trim(),
            };
        },
        checkf(val) {
            this.form_3 = val;
            // console.log(this.form_3)
        },
        async beforedown() {
            const phone = this.$cookies.get("phone");
            if (!phone) {
                this.$message.error("没有绑定手机号");
                return;
            }
            if (!this.proof) {
                this.dioA = true;
            } else {
                this.download();
            }
        },
        async download() {
            if (this.btn) {
                this.btn = false;
                // const { data: res } = await this.$http.get(
                //   '/admin/Common/checkSmsCode',
                //   {
                //     params: {
                //       ...this.form_3
                //     }
                //   }
                // )
                // this.btn = true
                // // console.log(this.btn)
                // this.$forceUpdate()
                if (false) {
                    // this.$message.error(res.message)
                    // return
                } else {
                    const { data: res } = await this.$http.get("/admin/UserSpeaker/exportExcel", {
                        params: {
                            ...this.form_2,
                            ...this.form_3,
                        },
                    });
                    this.proof = true;
                    this.btn = true;
                    if (res.errorCode == 200) {
                        this.$message.success(res.message);
                        location.href = res.data.url;
                        this.dioA = false;
                    }
                }
            }
        },
        add() {},
        handleAvatarSuccess(res, file) {
            if (res.errorCode == 200) {
                this.form.banner = res.data.url;
            }
            console.log(file);
            //   console.log(imgurlbase)
        },

        // beforeAvatarUpload(file) {
        //   const isJPG = file.type === 'image/jpeg'
        //   const isLt2M = file.size / 1024 / 1024 < 2

        //   if (!isJPG) {
        //     this.$message.error('上传头像图片只能是 JPG 格式!')
        //   }
        //   if (!isLt2M) {
        //     this.$message.error('上传头像图片大小不能超过 2MB!')
        //   }
        //   return isJPG && isLt2M
        // },
        handleAvatarSuccess_1(res, file) {
            if (res.errorCode == 200) {
                this.form_1.banner = res.data.url;
            }
            console.log(file);
            //   console.log(imgurlbase)
        },
        beforeAvatarUpload_1(file) {
            const isJPG = file.type === "image/jpeg";
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error("上传头像图片只能是 JPG 格式!");
            }
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
            }
            return isJPG && isLt2M;
        },

        addedit() {
            if (this.btn) {
                // this.form.picture = this.form.picture.slice(this.baseurl.length)

                this.$http.post("/admin/News/edit", this.form_1).then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.dialogVisiblexui = false;
                        this.$message.success(res.message);
                        this.getlist();
                    }
                    this.btn = true;
                });
            }
            this.btn = false;
        },
        edit(id) {
            // sessionStorage.setItem('flag', false)
            // this.p_a = true
            this.$router.push({
                name: "cooperation_list_add",
                query: {
                    id,
                },
            });
        },
        open(id) {
            this.$confirm("此操作将永久删除该, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$http.delete("/admin/cooperation/del", { params: { id: id } }).then((res) => {
                        //   console.log(res)
                        if (res.data.errorCode == 200) {
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                            if (this.total % 10 == 1) {
                                this.currentPage--;
                            }
                            this.getlist();
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        setTop(row, val) {
            // console.log(1)
            // console.log(row, id)
            this.$http
                .post("/admin/cooperation/top", {
                    id: row.id,
                    top: val,
                })
                .then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.$message.success(res.message);
                        this.getlist();
                    } else {
                        this.$message({
                            message: res.message,
                            type: "error",
                            customClass: "zZindex",
                        });
                    }
                    // this.btn1 = true
                });
        },
        show(id, el) {
            this.$http.post("/admin/Cooperation/hide", { id: id, isHide: el }).then(({ data: res }) => {
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                    this.getlist();
                }
            });
        },
        topLabel(row) {
            if (this.form_2.tag.trim()) {
                return row.categoryTop;
            }
            return row.top;
        },
        chagne(row) {
            this.$http
                .post("/admin/UserSpeaker/editService", {
                    id: row.id,
                    waId: row.waId.join(","),
                })
                .then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.getlist();
                    } else {
                        this.getlist();
                    }
                });
        },
    },
};
</script>

<style lang="less" scoped>
.out {
    // width: 500px;

    margin-bottom: 20px;
    .input {
        width: 37%;
        display: inline-block;
        margin-right: 30px;
    }
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
.skyblue {
    color: skyblue;
}
.green {
    color: green;
}
.yellow {
    color: pink;
}
.red {
    color: red;
}
.out .input {
    width: 18%;
}
.el-card {
    position: relative;
}
.out .right {
    float: right;
}
.box {
    display: inline-block;
    margin: 5px;
}
/deep/.el-table .cell {
    text-overflow: clip;
}
</style>
